<template>
  <AForm layout="vertical">
    <ARow :gutter="12">
      <ACol :span="12">
        <AFormItem label="Saved Cards" name="savedCards">
          <ASelect
            v-model:value="savedPaymentMethod"
            @change="setSavedPayment"
            :disabled="paymentMethods.length === 0"
          >
            <ASelectOption value="managePaymentMethods" @click="showModal">
              Manage Payment Methods
            </ASelectOption>
            <ASelectOption value="none"> None </ASelectOption>
            <ASelectOption
              v-for="(paymentMethod, paymentMethodIndex) in paymentMethods"
              :key="paymentMethodIndex"
              :value="paymentMethod"
            >
              {{ paymentMethod.number }}
            </ASelectOption>
          </ASelect>
        </AFormItem>
      </ACol>

      <ACol :span="12">
        <AFormItem label="Name" name="name" v-bind="validateInfos['name']">
          <AInput
            v-model:value="form.name"
            name="name"
            placeholder="Name"
            style="width: 100%"
            :disabled="disableFields"
          />
        </AFormItem>
      </ACol>

      <ACol :span="18">
        <label class="CreditCard__label" for="credit_card_field">
          Card Number
        </label>
        <div
          :id="lawPayFieldID.credit_card_number"
          class="lawpay-field lawpay-field--credit-card"
        >
          <img
            :src="require(`@/assets/images/cards/${cardBrand}.png`)"
            :alt="`${cardBrand}.png`"
            class="card-brand"
          />
        </div>
      </ACol>

      <ACol :span="6">
        <label class="CreditCard__label" for="cvv_field">CVV</label>
        <div
          :id="lawPayFieldID.cvv"
          class="lawpay-field lawpay-field--cvv"
        ></div>
      </ACol>

      <ACol :span="12">
        <AFormItem label="Expiration Date" v-bind="validateInfos['exp_date']">
          <AMonthPicker
            v-model:value="form.exp_date"
            :disabled-date="disabledDate"
            format="MM/YY"
            placeholder="MM/YY"
            style="width: 100%"
            :disabled="disableFields"
          />
        </AFormItem>
      </ACol>

      <ACol :span="12">
        <AFormItem label="Email" name="email" v-bind="validateInfos['email']">
          <AInput
            v-model:value="form.email"
            name="email"
            placeholder="Email"
            style="width: 100%"
            :disabled="disableFields"
          />
        </AFormItem>
      </ACol>

      <ACol :span="8">
        <AFormItem
          label="Address"
          name="address"
          v-bind="validateInfos['address1']"
        >
          <AInput
            v-model:value="form.address1"
            name="address"
            placeholder="Address"
            style="width: 100%"
            :disabled="disableFields"
          />
        </AFormItem>
      </ACol>

      <ACol :span="8">
        <AFormItem label="City" name="city" v-bind="validateInfos['city']">
          <AInput
            v-model:value="form.city"
            name="city"
            placeholder="City"
            style="width: 100%"
            :disabled="disableFields"
          />
        </AFormItem>
      </ACol>

      <ACol :span="8">
        <AFormItem
          label="State Code"
          name="state"
          v-bind="validateInfos['state']"
        >
          <ASelect
            v-model:value="form.state"
            show-search
            :disabled="disableFields"
            placeholder="Choose state code"
            :filter-option="filterOption"
          >
            <ASelectOption
              v-for="(state, stateIndex) in states"
              :key="stateIndex"
              :value="state"
            >
              {{ state }}
            </ASelectOption>
          </ASelect>
        </AFormItem>
      </ACol>

      <ACol :span="12">
        <AFormItem
          label="Postal Code"
          name="postal_code"
          v-bind="validateInfos['postal_code']"
        >
          <AInputNumber
            v-model:value="form.postal_code"
            type="number"
            :min="0"
            name="postal_code"
            placeholder="Postal Code"
            style="width: 100%"
            :disabled="disableFields"
          />
        </AFormItem>
      </ACol>

      <ACol :span="12">
        <AFormItem
          label="Country Code"
          name="country"
          v-bind="validateInfos['country']"
        >
          <AInput
            v-model:value="form.country"
            name="country"
            placeholder="Country"
            style="width: 100%"
            :disabled="true"
          />
        </AFormItem>
      </ACol>
    </ARow>
  </AForm>

  <AModal
    v-model:visible="visible"
    title="Manage Payment Methods"
    width="800px"
    bodyStyle="height:400px;overflow-y:scroll;"
  >
    <AList item-layout="horizontal" :data-source="paymentMethods">
      <template #renderItem="{ item }">
        <AListItem>
          <template #actions>
            <AButton
              v-if="item.confirmDelete"
              shape="round"
              size="small"
              @click="item.confirmDelete = false"
            >
              Cancel
            </AButton>
            <AButton
              v-if="item.confirmDelete"
              shape="round"
              size="small"
              type="danger"
              :loading="loading"
              @click="deletePaymentMethod(item)"
            >
              Confirm
            </AButton>
            <a v-else style="color: red" @click="item.confirmDelete = true"
              >Delete</a
            >
          </template>
          <AListItemMeta>
            <template #title>
              <b>{{ item.name }}</b>
              <div style="margin-left: 20px">
                <b>Number:</b> {{ item.number }}
              </div>
              <div style="margin-left: 20px">
                <b>Exp Date:</b> {{ item.exp_month }}/{{ item.exp_year }}
              </div>
            </template>
          </AListItemMeta>
        </AListItem>
      </template>
    </AList>
    <template #footer>
      <AButton key="back" type="primary" @click="handleClose"> Close </AButton>
    </template>
  </AModal>

  <slot :validateFn="validateFn" :lawPayRequestFn="lawPayRequest" />
</template>

<script lang="ts">
import { defineComponent, reactive, ref, watchEffect } from 'vue';
import { useForm } from '@ant-design-vue/use';

import {
  LawPayPaymentCardResponse,
  LawPayState,
  PaymentForm,
} from '@/types/payment-form.type';
import { Validator } from '@/utils/ant-custom-validators';
import useLawpay from '@/composables/use-lawpay';
import moment from 'moment';
import { useGlobalProps } from '@/composables';
import { IntegrationsService } from '@/services';
// import { CloseOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';

export default defineComponent({
  props: {
    contactId: {
      type: Number,
    },
  },
  components: {
    // CloseOutlined,
  },
  setup(props) {
    const { setLoading, states } = useGlobalProps();
    const form = reactive<PaymentForm['credit_card']>({
      name: '',
      email: '',
      address1: '',
      city: '',
      state: undefined,
      exp_date: '',
      postal_code: '',
      cvv: '',
      country: '',
    });

    form.country = 'US';

    /** Credit Card logo */
    const cardBrand = ref('card');

    /** Typing on LawPay fields */
    const lawPayFieldsCallback = (state: LawPayState) => {
      const { target } = state;

      // Set card brand
      if (target.type === 'credit_card_number') {
        if (!!target.card && target.card !== 'unknown') {
          cardBrand.value = target.card;
        } else {
          cardBrand.value !== 'card' && (cardBrand.value = 'card');
        }
      }
    };

    const { lawPayFieldID, lawPayHostedField, validateForm } = useLawpay(
      'card',
      lawPayFieldsCallback,
    );

    /** Form validation rules (AntDesign) */
    const rules = reactive({
      name: [
        {
          validator: Validator.isRequired(),
          trigger: 'change',
        },
      ],
      email: [
        {
          type: 'email',
          message: 'The input is not valid E-mail!',
        },
        {
          validator: Validator.isRequired(),
          trigger: 'change',
        },
      ],
      address1: [
        {
          validator: Validator.isRequired(),
          trigger: 'change',
        },
      ],
      city: [
        {
          validator: Validator.isRequired(),
          trigger: 'change',
        },
      ],
      state: [
        {
          validator: Validator.isRequired(),
          trigger: 'change',
        },
        {
          validator: Validator.len('equals', 2),
          trigger: 'change',
          type: 'string',
        },
      ],
      country: [
        {
          validator: Validator.isRequired(),
          trigger: 'change',
        },
        {
          validator: Validator.len('equals', 2),
          trigger: 'change',
          type: 'string',
        },
      ],
      postal_code: [
        {
          validator: Validator.isRequired(),
          trigger: 'change',
        },
        {
          validator: Validator.len('equals', 5),
          trigger: 'change',
          type: 'string',
        },
      ],
      exp_date: [
        {
          validator: Validator.isRequired('Please pick a date'),
          trigger: 'change',
          type: 'object',
        },
      ],
    });

    const disableFields = ref(false);

    const paymentMethods = ref<any[]>([]);

    const savedPaymentMethod = ref<any>({});

    const loading = ref<boolean>(false);

    const visible = ref<boolean>(false);

    /** Form validation result (AntDesign) */
    const { validateInfos, validate } = useForm(form, rules);

    /** Disable date in MonthPicker */
    const disabledDate = (current: any) => {
      // Can not select days before today and today
      return current && current < moment().endOf('day');
    };

    /** Validate form fields */
    const validateFn = () => validateForm(validate);

    /** Add payment method */
    const addPaymentMethod = async (payload: any) => {
      try {
        const result = await IntegrationsService.addPaymentMethod({
          contactId: props.contactId,
          paymentMethodId: payload.id,
          integration: 'LawPay',
        });

        return result;
      } catch (error) {
        console.log(error);
      }
    };

    /** Execute LawPay request */
    const lawPayRequest = async (): Promise<LawPayPaymentCardResponse> => {
      let result;

      if (savedPaymentMethod.value.id)
        result = JSON.parse(JSON.stringify(savedPaymentMethod.value));
      else {
        // Validate form fields integrity
        if (!(await validateFn())) {
          return Promise.reject(new Error('Invalid form fields'));
        }

        const date = moment(form.exp_date);
        const [exp_month, exp_year] = [date.format('MM'), date.format('YY')];

        try {
          setLoading(true);
          result = await lawPayHostedField.value.getPaymentToken<
            LawPayPaymentCardResponse
          >({
            name: form.name,
            email: form.email,
            address1: form.address1,
            city: form.city,
            country: form.country,
            state: form.state,
            postal_code: String(form.postal_code),
            exp_year,
            exp_month,
          });

          const responseAddPaymentMethod = JSON.parse(
            JSON.stringify(await addPaymentMethod(result)),
          );

          result.id = responseAddPaymentMethod.lawPayPaymentMethod.pop();
        } catch (error) {
          return Promise.reject(error);
        } finally {
          setLoading(false);
        }
      }

      if (result && result !== null) {
        window.top.postMessage(result, process.env.VUE_APP_PRIMA_URL);

        return Promise.resolve(result);
      } else {
        message.success('Lawpay response is invalid.');
        return Promise.reject('Lawpay response is invalid.');
      }
    };

    const clearSavedPaymentMethod = () => {
      disableFields.value = false;
      lawPayHostedField.value.clearSavedPaymentMethod();
      form.name = '';
      form.state = undefined;
      form.exp_date = '';
      form.postal_code = '';
      form.email = '';
      form.address1 = '';
      form.city = '';
      form.country = 'US';
      savedPaymentMethod.value = 'none';
    };

    const getPaymentMethods = async () => {
      const data: any = await IntegrationsService.getPaymentMethods(
        Number(props.contactId),
      );

      if (data[0] && data[0] !== null) {
        paymentMethods.value = data[0].paymentMethods;

        clearSavedPaymentMethod();

        if (paymentMethods.value.length === 0) visible.value = false;
      }
    };

    const setSavedPayment = async () => {
      if (savedPaymentMethod.value === 'none') clearSavedPaymentMethod();
      else if (savedPaymentMethod.value !== 'managePaymentMethods') {
        disableFields.value = true;
        lawPayHostedField.value.setSavedPaymentMethod(savedPaymentMethod.value);
        form.name = savedPaymentMethod.value.name;
        form.state = savedPaymentMethod.value.state;
        moment.defaultFormat = 'DD.MM.YYYY';
        form.exp_date = String(
          moment(
            '08/' +
              savedPaymentMethod.value.exp_month +
              '/20' +
              savedPaymentMethod.value.exp_year,
            moment.defaultFormat,
          ).toDate(),
        );
        form.email = savedPaymentMethod.value.email;
        form.address1 = savedPaymentMethod.value.address1;
        form.city = savedPaymentMethod.value.city;
        form.postal_code = savedPaymentMethod.value.postal_code;
        form.country = savedPaymentMethod.value.country;
      }
    };

    watchEffect(() => {
      getPaymentMethods();

      if (visible.value === false && lawPayHostedField.value) {
        clearSavedPaymentMethod();
      }
    });

    const showModal = () => {
      visible.value = true;
    };

    const handleClose = () => {
      visible.value = false;
    };

    const deletePaymentMethod = async (paymentMethod: any) => {
      loading.value = true;
      const data = await IntegrationsService.deletePaymentMethod(
        Number(props.contactId),
        paymentMethod.id,
      );

      if (data && data !== null) {
        loading.value = false;

        getPaymentMethods();
      }
    };

    const filterOption = (input: string, option: any) => {
      return option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    return {
      form,
      cardBrand,
      lawPayFieldID,
      validateInfos,
      disabledDate,
      validateFn,
      lawPayRequest,
      lawPayHostedField,
      paymentMethods,
      savedPaymentMethod,
      setSavedPayment,
      disableFields,
      deletePaymentMethod,
      showModal,
      handleClose,
      visible,
      states,
      filterOption,
    };
  },
});
</script>

<style lang="scss" scoped>
.card-brand {
  position: absolute;
  right: 8px;
  height: 24px;
  top: 3px;
  pointer-events: none;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}
</style>
